<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-shopping-cart-full"> </i> 物料管理 | 库存信息</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit()">新增物品</el-button>

            <el-button type="warning" @click="imp(true)">导入物品(仅表格)</el-button>
            <el-button type="primary" @click="imp(false)">导入物品(带图片)</el-button>
            <!-- <form id="fm_export" method="post" action="/mat/stock/export" target="_blank" style="display: inline-block; margin: 0px 10px">
              <input type="hidden" name="KEY" v-model="grid.sea.KEY" />
              <input type="hidden" name="CAT_ID" v-model="grid.sea.CAT_ID" />
              <input type="hidden" name="STA" v-model="grid.sea.STA" /> -->
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <!-- </form> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 5px;">
              <el-select v-model="grid.sea.STORAGE_ID" @change="filter" placeholder="请选择仓库" clearable class="cxf">
                <el-option :label="it.STORAGE_NAME" :value="it.ID" v-for="it in store" :key="it.ID" />
              </el-select>
              <el-cascader v-model="cat.val" :options="cat.ls" placeholder="请选择分类" clearable class="cxf"
                @change="filter" :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN', checkStrictly: true }" />
              <el-select v-model="grid.sea.STA" @change="filter" clearable class="cxf se" placeholder="请选择状态">
                <el-option label="正常" :value="1" />
                <el-option label="禁用" :value="2" />
              </el-select>
              <el-select v-model="grid.sea.StockState" @change="filter" clearable class="cxf se" placeholder="请选择">
                <el-option label="全部" :value="0" />
                <el-option label="零库存" :value="1" />
                <el-option label="有库存" :value="2" />
                <el-option label="低值库存" :value="3" />
              </el-select>
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" class="cxf in">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage">
              <el-table-column prop="STORAGE_NAME" label="仓库名称" width="100" align="center" />
              <el-table-column prop="STORAGE_LOCATION" label="位置" width="100" align="center" />
              <el-table-column prop="CAT_NAME" label="分类全称" width="200" align="center" />
              <el-table-column prop="NAME" label="物品名称" width="200" />
              <el-table-column label="图片" width="50" align="center">
                <template align="center" slot-scope="scope">
                  <el-image v-if="scope.row.PIC_NAME" style="width: 50px; margin-left: -10px; margin-top: 5px"
                    :src="scope.row.PIC_URL" :preview-src-list="[scope.row.PIC_URL]" />
                </template>
              </el-table-column>
              <el-table-column label="数量" width="100" align="center">
                <template slot-scope="scope">
                  {{ scope.row.BALANCE }}
                  <!-- ({{ scope.row.FUNIT }}) -->
                </template>
              </el-table-column>
              <el-table-column label="单位" align="center">
                <template slot-scope="scope">
                  {{ scope.row.FUNIT }}
                </template>
              </el-table-column>
              <el-table-column label="单价(元)" width="100" align="center">
                <template slot-scope="scope">
                  ￥{{ scope.row.PRICE.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="金额(元)" width="100" align="center">
                <template slot-scope="scope">
                  ￥{{ scope.row.AMOUNT.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column prop="ID" label="编号" width="80" align="center" />
              <el-table-column prop="STA_TXT" label="状态" width="60" align="center" />
              <el-table-column prop="LOWER_LIMIT" label="低值通知" width="80" align="center" />
              <el-table-column prop="DESCRIPTION" label="备注" width="80" align="center" />
              <el-table-column label="操作" min-width="200">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)">修改</el-button>
                  <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)">删除</el-button>
                  <el-button type="text" icon="el-icon-tickets" @click="getRecord(scope.row)">流水</el-button>
                  <el-button type="text" icon="el-icon-edit" @click="rowLoss(scope.row)">损耗</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="stock_edit" @on-saved="getList" />
    </div>
    <div>
      <IMP ref="stock_imp" @on-saved="filter" />
    </div>
    <div>
      <IMP2PIC ref="stock_imp_pic" @on-saved="filter" />
    </div>
    <div>
      <Record ref="stock_record" @openMat="getMatEdit" />
    </div>
    <div>
      <Loss ref="stock_loss" @on-saved="getList" />
    </div>

    <div>
      <MatEdit ref="matEdit" />
    </div>
  </div>
</template>
<style>
  .el-radio {
    padding-top: 10px
  }
</style>
<script>
  import Loss from "./loss";
  import Edit from "./edit";
  import MatEdit from "@/views/mat/valid/act/edit";
  import IMP from './imp'
  import IMP2PIC from './imp2pic'
  import Record from "@/views/mat/stock/record";
  import Wgrid from "@/components/wgrid";
  import jsFileDownload from 'js-file-download'

  export default {
    name: "index",
    components: { Wgrid, Loss, Edit, IMP, Record, IMP2PIC, MatEdit },
    data() {
      return {
        grid: {
          sea: {
            PAGE_SIZE: 12,
            PAGE_INDEX: 0,
            STA: 1,
            StockState: 0,
            STORAGE_ID: 0
          },
          ls: [],
          total: 0,
          loading: false
        },
        cat: {
          ls: [],
          all: [],
          val: []
        },
        store: []
      }
    },
    created() {
      this.getCat();
      // this.getStore();
      // this.getList();
    },
    methods: {
      filter() {
        if (this.grid.sea.STORAGE_ID > 0) {
          this.cat.ls = this.cat.all.filter(it => it.STORAGE_ID == this.grid.sea.STORAGE_ID)
        } else
          this.cat.ls = []
        let n = this.cat.val.length;
        this.grid.sea.CAT_ID = n > 0 ? this.cat.val[n - 1] : 0;
        this.grid.sea.PAGE_INDEX = 0;
        this.grid.ls = [];
        this.getList();
      },
      loadPage(pi) {
        this.grid.sea.PAGE_INDEX = pi - 1;
        this.getList();
      },
      getList() {
        let self = this;
        this.grid.loading = true;
        this.whale.remote.getCollection({
          url: "/api/School/MAT/StockApi/GetList",
          data: this.grid.sea,
          completed: function (its, n) {
            self.grid.ls = its;
            self.grid.total = n;
            self.grid.loading = false;
          }
        })
      },
      getCat() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/MAT/MCatApi/GetTree",
          completed: function (its) {
            self.cat.all = its.DATA;
            self.getStore();
          }
        })
      },
      getStore() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/MAT/MatStorage/GetList",
          completed: function (its) {
            self.store = its.DATA;
            if (its.DATA.length > 0) {
              self.grid.sea.STORAGE_ID = self.store[0].ID;
              self.cat.ls = self.cat.all.filter(it => it.STORAGE_ID == self.grid.sea.STORAGE_ID)
            }
            self.filter();
          }
        })
      },
      rowLoss(r) {
        this.$refs.stock_loss.init(r);
      },
      rowEdit(r) {
        this.$refs.stock_edit.init(r);
      },
      rowDel(r) {
        this.whale.delConfirm(() => {
          let self = this;
          this.whale.remote.getResult({
            url: "/api/School/MAT/StockApi/Delete",
            data: { ID: r.ID },
            completed: function () {
              //self.filter();
              self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
            }
          })
        });
      },
      getRecord(r) {
        this.$refs.stock_record.init(r);
      },
      getMatEdit(r) {
        this.$refs.matEdit.init(0, r);
      },
      imp(onlyExl = true) {
        if (onlyExl)
          this.$refs.stock_imp.init();
        else
          this.$refs.stock_imp_pic.init();
      },
      exportExcel() {
        let self = this;
        this.grid.loading = true;
        const formData = new FormData()
        formData.append('KEY', this.grid.sea.KEY || '')
        formData.append('CAT_ID', this.grid.sea.CAT_ID || '')
        formData.append('STA', this.grid.sea.STA || '')
        formData.append('STORAGE_ID', this.grid.sea.STORAGE_ID || '')
        this.whale.remote.getExport({
          url: "/api/School/MAT/MStock/Export",
          data: formData,
          completed: function (its) {
            // console.log('exls', its.Data)
            // self.whale.downLoad(its.Data)

            jsFileDownload(its, '导出物料管理库存信息.xls')
            self.grid.loading = false;
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .cxf {
    margin-left: 5px;
  }

  .se {
    width: 150px;
  }

  .in {
    width: 300px;
  }
</style>